import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './KegelTabelle.css';

function KegelTabelle() {
  const [kegler, setKegler] = useState([]);
  const [spiele, setSpiele] = useState([]);
  const [ergebnisse, setErgebnisse] = useState([]);
  const [platzierungen, setPlatzierungen] = useState({});
  const [tagessiege, setTagessiege] = useState([]);
  const [gesamtSpezialerfassungen, setGesamtSpezialerfassungen] = useState({
    Pumpen: [],
    Neunen: [],
    Kranze: [],
  });

  useEffect(() => {
    axios.get('/api/kegler')
      .then(response => {
        setKegler(response.data);
        fetchSpiele(response.data);  // Übergibt Kegler-Daten an fetchSpiele
      })
      .catch(error => console.error('Error fetching Kegler data:', error));
  }, []);

  const fetchSpiele = (keglerData) => {
    axios.get('/api/spiele')
      .then(response => {
        setSpiele(response.data);
        fetchErgebnisse(response.data, keglerData);  // Übergibt Kegler-Daten an fetchErgebnisse
      })
      .catch(error => console.error('Error fetching Spiele data:', error));
  };

  const fetchErgebnisse = (spiele, keglerData) => {
    axios.get('/api/ergebnisse')
      .then(response => {
        setErgebnisse(response.data);
        calculatePlatzierungen(response.data, spiele, keglerData);  // Übergibt Kegler-Daten an calculatePlatzierungen
      })
      .catch(error => console.error('Error fetching Ergebnisse data:', error));
  };

  const calculatePlatzierungen = (allErgebnisse, spiele, keglerData) => {
    const platzierungenPerSpiel = {};
    const siegesCount = {};

    spiele.forEach(spiel => {
      const spielErgebnisse = allErgebnisse.filter(e => e.spiel.id === spiel.id);
      const validErgebnisse = spielErgebnisse.filter(e => e.l1 !== 0 || e.r1 !== 0 || e.l2 !== 0 || e.r2 !== 0);

      const totalPoints = validErgebnisse.map(e => ({
        ...e,
        total: e.l1 + e.r1 + e.l2 + e.r2
      }));

      totalPoints.sort((a, b) => b.total - a.total);

      const platzierungen = totalPoints.map((e, index) => {
        if (index === 0) {
          if (siegesCount[e.kegler.id]) {
            siegesCount[e.kegler.id]++;
          } else {
            siegesCount[e.kegler.id] = 1;
          }
        }
        return {
          ...e,
          platzierung: index + 1
        };
      });

      platzierungenPerSpiel[spiel.id] = platzierungen;
    });

    const sortedSieges = Object.entries(siegesCount).sort((a, b) => b[1] - a[1]);
    const top5Sieges = sortedSieges.slice(0, 5).map(([keglerId, count]) => {
      const keglerName = keglerData.find(k => k.id === parseInt(keglerId))?.name || 'Unbekannt';
      return { keglerName, count };
    });

    setPlatzierungen(platzierungenPerSpiel);
    setTagessiege(top5Sieges);
    calculateGesamtSpezialerfassungen(allErgebnisse, keglerData);
  };

  const calculateGesamtSpezialerfassungen = (allErgebnisse, keglerData) => {
    const spezialCounts = {
      Pumpen: {},
      Neunen: {},
      Kranze: {},
    };

    allErgebnisse.forEach(e => {
      if (!spezialCounts.Pumpen[e.kegler.id]) {
        spezialCounts.Pumpen[e.kegler.id] = 0;
      }
      spezialCounts.Pumpen[e.kegler.id] += e.pumpen;

      if (!spezialCounts.Neunen[e.kegler.id]) {
        spezialCounts.Neunen[e.kegler.id] = 0;
      }
      spezialCounts.Neunen[e.kegler.id] += e.neunen;

      if (!spezialCounts.Kranze[e.kegler.id]) {
        spezialCounts.Kranze[e.kegler.id] = 0;
      }
      spezialCounts.Kranze[e.kegler.id] += e.kraenze;
    });

    const top5Pumpen = Object.entries(spezialCounts.Pumpen).sort((a, b) => b[1] - a[1]).slice(0, 5).map(([keglerId, count]) => {
      const keglerName = keglerData.find(k => k.id === parseInt(keglerId))?.name || 'Unbekannt';
      return { keglerName, count };
    });

    const top5Neunen = Object.entries(spezialCounts.Neunen).sort((a, b) => b[1] - a[1]).slice(0, 5).map(([keglerId, count]) => {
      const keglerName = keglerData.find(k => k.id === parseInt(keglerId))?.name || 'Unbekannt';
      return { keglerName, count };
    });

    const top5Kranze = Object.entries(spezialCounts.Kranze).sort((a, b) => b[1] - a[1]).slice(0, 5).map(([keglerId, count]) => {
      const keglerName = keglerData.find(k => k.id === parseInt(keglerId))?.name || 'Unbekannt';
      return { keglerName, count };
    });

    setGesamtSpezialerfassungen({
      Pumpen: top5Pumpen,
      Neunen: top5Neunen,
      Kranze: top5Kranze,
    });
  };

  return (
    <div className="kegel-tabelle">
      <h2>Kegel Tabelle</h2>

      <div className="uebersicht">
        <div className="spezial-tabellen">
          <div>
            <h3>Top 5 Tagessiege</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                {tagessiege.map((s, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{s.keglerName}</td>
                    <td>{s.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <h3>Top 5 Pumpen</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                {gesamtSpezialerfassungen.Pumpen.map((s, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{s.keglerName}</td>
                    <td>{s.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <h3>Top 5 Neunen</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                {gesamtSpezialerfassungen.Neunen.map((s, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{s.keglerName}</td>
                    <td>{s.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <h3>Top 5 Kranze</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                {gesamtSpezialerfassungen.Kranze.map((s, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{s.keglerName}</td>
                    <td>{s.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {spiele.map(spiel => (
        <div key={spiel.id} className="spiel-abschnitt">
          <h3>{spiel.beschreibung} - {new Date(spiel.datum).toLocaleDateString()}</h3>
          <table>
            <thead>
              <tr>
                <th>Platzierung</th>
                <th>Kegler</th>
                <th>l1</th>
                <th>r1</th>
                <th>l2</th>
                <th>r2</th>
                <th>Gesamt</th>
                <th>Pumpen</th>
                <th>Neunen</th>
                <th>Kranze</th>
              </tr>
            </thead>
            <tbody>
              {platzierungen[spiel.id] && platzierungen[spiel.id].map((p, index) => (
                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <td>{p.platzierung}</td>
                  <td>{kegler.find(k => k.id === p.kegler.id)?.name || 'Unbekannt'}</td>
                  <td>{p.l1}</td>
                  <td>{p.r1}</td>
                  <td>{p.l2}</td>
                  <td>{p.r2}</td>
                  <td>{p.total}</td>
                  <td>{p.pumpen}</td>
                  <td>{p.neunen}</td>
                  <td>{p.kraenze}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default KegelTabelle;
