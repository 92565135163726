import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import KegelTabelle from './components/KegelTabelle';
import logo from './logo.png'; // Stellen Sie sicher, dass der Pfad zu Ihrem Logo korrekt ist
import './App.css';
import Login from './components/Login';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLogin = (status) => {
    console.log("Admin login status:", status);
    setIsAdmin(status);
  };

  return (
    <Router>
      <div className="App">
      <header className="app-header">
          <img src={logo} alt="Kegelklub Logo" className="app-logo" />
          <h1>Kegelklub Blau-Weiß</h1>
        </header>
        {!isLoggedIn ? (
          <Login onLogin={setIsLoggedIn} />
        ) : (
          <Routes>
            <Route
              path="/admin"
              element={isAdmin ? <AdminDashboard /> : <AdminLogin onLogin={handleLogin} />}
            />
            <Route path="/" element={<KegelTabelle />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
