import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css';

function AdminDashboard() {
  const [kegler, setKegler] = useState([]);
  const [spiele, setSpiele] = useState([]);
  const [selectedSpiel, setSelectedSpiel] = useState(null);
  const [spielBeschreibung, setSpielBeschreibung] = useState('');
  const [spielDatum, setSpielDatum] = useState('');
  const [ergebnisse, setErgebnisse] = useState([]);

  useEffect(() => {
    axios.get('/api/kegler')
      .then(response => setKegler(response.data))
      .catch(error => console.error('Error fetching Kegler data:', error));

    axios.get('/api/spiele')
      .then(response => setSpiele(response.data))
      .catch(error => console.error('Error fetching Spiele data:', error));
  }, []);

  useEffect(() => {
    if (selectedSpiel) {
      axios.get(`/api/ergebnisse?spielId=${selectedSpiel.id}`)
        .then(response => {
          console.log('Fetched Ergebnisse:', response.data);
          setErgebnisse(response.data);
        })
        .catch(error => console.error('Error fetching Ergebnisse data:', error));
    }
  }, [selectedSpiel]);

  const handleNewSpielSubmit = (event) => {
    event.preventDefault();
    const spiel = { beschreibung: spielBeschreibung, datum: spielDatum };

    axios.post('/api/spiele', spiel)
      .then(response => {
        const newSpiel = response.data;
        setSpiele([...spiele, newSpiel]);
        submitErgebnisse(newSpiel.id);
        resetForm();
      })
      .catch(error => console.error('Error creating Spiel:', error));
  };

  const handleUpdateSpielSubmit = (event) => {
    event.preventDefault();
    const spiel = { beschreibung: spielBeschreibung, datum: spielDatum };

    axios.put(`/api/spiele/${selectedSpiel.id}`, spiel)
      .then(response => {
        setSpiele(spiele.map(s => s.id === selectedSpiel.id ? response.data : s));
        submitErgebnisse(selectedSpiel.id);
        resetForm();
      })
      .catch(error => console.error('Error updating Spiel:', error));
  };

  const resetForm = () => {
    setSelectedSpiel(null);
    setSpielBeschreibung('');
    setSpielDatum('');
    setErgebnisse([]);
  };

  const submitErgebnisse = (spielId) => {
    ergebnisse.forEach(ergebnis => {
      const updatedErgebnis = { ...ergebnis, spiel: { id: spielId }, kegler: { id: ergebnis.kegler.id } };
      console.log('Submitting Ergebnis:', updatedErgebnis);
      if (ergebnis.id) {
        axios.put(`/api/ergebnisse/${ergebnis.id}`, updatedErgebnis)
          .then(response => console.log('Ergebnis aktualisiert:', response.data))
          .catch(error => console.error('Error updating Ergebnis:', error));
      } else {
        axios.post('/api/ergebnisse', updatedErgebnis)
          .then(response => console.log('Ergebnis hinzugefügt:', response.data))
          .catch(error => console.error('Error adding Ergebnis:', error));
      }
    });
  };

  const handleSpielEdit = (spiel) => {
    setSelectedSpiel(spiel);
    setSpielBeschreibung(spiel.beschreibung);
    setSpielDatum(spiel.datum);
  };

  const handleSpielDelete = (id) => {
    axios.delete(`/api/spiele/${id}`)
      .then(() => setSpiele(spiele.filter(spiel => spiel.id !== id)))
      .catch(error => console.error('Error deleting Spiel:', error));
  };

  const handleErgebnisChange = (keglerId, field, value) => {
    setErgebnisse(prevErgebnisse => {
      const newErgebnisse = [...prevErgebnisse];
      const index = newErgebnisse.findIndex(e => e.kegler.id === keglerId && e.spiel && e.spiel.id === (selectedSpiel ? selectedSpiel.id : null));
      if (index === -1) {
        const newErgebnis = { kegler: { id: keglerId }, spiel: { id: (selectedSpiel ? selectedSpiel.id : null) }, l1: 0, r1: 0, l2: 0, r2: 0, pumpen: 0, neunen: 0, kraenze: 0 };
        newErgebnis[field] = value;
        newErgebnisse.push(newErgebnis);
      } else {
        newErgebnisse[index] = { ...newErgebnisse[index], [field]: value };
      }
      return newErgebnisse;
    });
  };

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>

      <form onSubmit={selectedSpiel ? handleUpdateSpielSubmit : handleNewSpielSubmit} className="spiel-form">
        <h3>{selectedSpiel ? 'Spiel bearbeiten' : 'Neues Spiel hinzufügen'}</h3>
        <label>
          Beschreibung:
          <input type="text" value={spielBeschreibung} onChange={(e) => setSpielBeschreibung(e.target.value)} required />
        </label>
        <label>
          Datum:
          <input type="date" value={spielDatum} onChange={(e) => setSpielDatum(e.target.value)} required />
        </label>
        <div className="buttons">
          {!selectedSpiel && <button type="submit">Neues Spiel hinzufügen</button>}
          {selectedSpiel && <button type="submit">Aktualisieren</button>}
          {selectedSpiel && <button type="button" onClick={resetForm}>Abbrechen</button>}
        </div>
      </form>

      <h3>Bestehende Spiele</h3>
      <select onChange={(e) => handleSpielEdit(spiele.find(spiel => spiel.id === parseInt(e.target.value)))}>
        <option value="">-- Wählen Sie ein Spiel --</option>
        {spiele.map(spiel => (
          <option key={spiel.id} value={spiel.id}>
            {spiel.beschreibung} - {new Date(spiel.datum).toLocaleDateString()}
          </option>
        ))}
      </select>

      {selectedSpiel && (
        <>
          <h3>Ergebnisse für {selectedSpiel.beschreibung}</h3>
          {kegler.map(k => {
            const ergebnis = ergebnisse.find(e => e.kegler.id === k.id && e.spiel.id === selectedSpiel.id) || { l1: 0, r1: 0, l2: 0, r2: 0, pumpen: 0, neunen: 0, kraenze: 0 };

            return (
              <div key={k.id} className="kegler-ergebnis">
                <h4>{k.name}</h4>
                <label>
                  l1:
                  <input type="number" value={ergebnis.l1} onChange={(e) => handleErgebnisChange(k.id, 'l1', parseInt(e.target.value))} />
                </label>
                <label>
                  r1:
                  <input type="number" value={ergebnis.r1} onChange={(e) => handleErgebnisChange(k.id, 'r1', parseInt(e.target.value))} />
                </label>
                <label>
                  l2:
                  <input type="number" value={ergebnis.l2} onChange={(e) => handleErgebnisChange(k.id, 'l2', parseInt(e.target.value))} />
                </label>
                <label>
                  r2:
                  <input type="number" value={ergebnis.r2} onChange={(e) => handleErgebnisChange(k.id, 'r2', parseInt(e.target.value))} />
                </label>
                <label>
                  Pumpen:
                  <input type="number" value={ergebnis.pumpen} onChange={(e) => handleErgebnisChange(k.id, 'pumpen', parseInt(e.target.value))} />
                </label>
                <label>
                  Neunen:
                  <input type="number" value={ergebnis.neunen} onChange={(e) => handleErgebnisChange(k.id, 'neunen', parseInt(e.target.value))} />
                </label>
                <label>
                  Kränze:
                  <input type="number" value={ergebnis.kraenze} onChange={(e) => handleErgebnisChange(k.id, 'kraenze', parseInt(e.target.value))} />
                </label>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default AdminDashboard;

