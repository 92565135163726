import React, { useState } from 'react';

function Login({ onLogin }) {
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'Kegel1956') {
      console.log('Login successful');
      onLogin(true);
    } else {
      console.log('Incorrect password');
      alert('Falsches Passwort');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passwort"
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
