import React, { useState } from 'react';

function AdminLogin({ onLogin }) {
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Attempted login with password:", password);
    if (password === 'adminpassword') {  // Setzen Sie hier ein sicheres Passwort
      console.log("Login successful");
      onLogin(true);
    } else {
      console.log("Login failed");
      alert('Invalid password');
    }
  };

  return (
    <div className="admin-login">
      <form onSubmit={handleSubmit}>
        <label>
          Admin Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default AdminLogin;
